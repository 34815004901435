import React from 'react';
import classnames from 'classnames';

const MaxWidthContainer = (props) => {
    const { className, children, ...restProps } = props;

    return (
        <div
            className={classnames(
                'mx-auto w-full max-w-screen-2xl lg:px-8 2xl:px-20',
                className
            )}
            {...restProps}
        >
            {children}
        </div>
    );
};

export default MaxWidthContainer;
