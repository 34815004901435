import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import { useFloating } from '@floating-ui/react';
import { offset } from '@floating-ui/dom';

import { MixpanelConfig } from 'utils/mixpanelConfig';

import MaxWidthContainer from 'components/MaxWidthContainer';

import redirectToLogin from 'utils/redirectToLogin';
import { useRouter } from 'next/router';

import api from 'utils/api';
import { useAppLaunch } from 'contexts/AppLaunch';
import { useUserAuth } from 'contexts/UserAuth';

const { useEffect, useState } = React;

const Menubar = (props) => {
  var backgroundOpacity = 0.0;

  const { ...restProps } = props;

  const { user: sorraUser, getAuthorizationHeader } = useUserAuth();
  const { notifications } = useAppLaunch();

  const router = useRouter();
  const { asPath, pathname, query } = router;

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-end',
    middleware: [offset(18)],
  });

  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);

  const [existedVotingResult, setExistedVotingResult] = useState(false);

  const uploadVotingResult = async (payload) => {
    try {
      const headers = await getAuthorizationHeader();
      await api.post(`/api/2024/votingResult`, payload, { headers });
      localStorage.removeItem('2024_votings');
      localStorage.removeItem('2024_voting_result');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const votingResult = localStorage.getItem('2024_voting_result');
    if (!_.isEmpty(votingResult)) {
      const parsedVotingResult = JSON.parse(votingResult);
      if (parsedVotingResult) {
        setExistedVotingResult(parsedVotingResult);
      }
    }
  }, []);

  useEffect(() => {
    if (sorraUser && existedVotingResult) {
      uploadVotingResult(existedVotingResult);
    }
  }, [sorraUser, existedVotingResult]);

  useEffect(() => {
    let interval = null;
    if (notifications.length > 0) {
      interval = setInterval(() => {
        setCurrentNotificationIndex((prev) => {
          return prev + 1 >= notifications.length ? 0 : prev + 1;
        });
      }, 3000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };
  }, [notifications]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refs.floating.current &&
        !refs.floating.current.contains(event.target) &&
        !refs.reference.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, []);

  const [searchLoadedState, setSearchLoadedState] = useState(false);
  const [searchBrandState, setSearchBrandState] = useState('');
  const [searchProductState, setSearchProductState] = useState('');
  const [searchCategoryState, setSearchCategoryState] = useState('');
  const [searchCategoryIDState, setSearchCategoryIDState] = useState('');
  const [searchKeywordState, setSearchKeywordState] = useState('');
  const [searchDisplayState, setSearchDisplayState] = useState('');

  if (restProps.props.homeExtend == true) {
    backgroundOpacity = restProps.props.scrollPosition;
  } else {
    backgroundOpacity = 1.0;
  }

  if (searchLoadedState == false) {
    var searchLoaded = false;

    if (restProps.props.searchBrand && restProps.props.searchBrand != null) {
      setSearchBrandState(restProps.props.searchBrand);

      searchLoaded = true;
    }

    if (
      restProps.props.searchProduct &&
      restProps.props.searchProduct != null
    ) {
      setSearchProductState(restProps.props.searchProduct);

      searchLoaded = true;
    }

    if (
      restProps.props.searchCategory &&
      restProps.props.searchCategory != null
    ) {
      setSearchCategoryState(restProps.props.searchCategory);

      searchLoaded = true;
    }

    if (
      restProps.props.searchCategoryID &&
      restProps.props.searchCategoryID != null
    ) {
      setSearchCategoryIDState(restProps.props.searchCategoryID);

      searchLoaded = true;
    }

    if (
      restProps.props.searchKeyword &&
      restProps.props.searchKeyword != null
    ) {
      setSearchKeywordState(restProps.props.searchKeyword);

      searchLoaded = true;
    }

    if (searchLoaded == true) {
      setSearchLoadedState(true);

      var searchDisplayTmp = '';

      if (restProps.props.searchBrand != '') {
        searchDisplayTmp += restProps.props.searchBrand + ' ';
      }

      searchDisplayTmp += restProps.props.searchProduct;

      if (searchDisplayTmp != '' && restProps.props.searchCategory != '') {
        searchDisplayTmp += ' • ';
      }

      searchDisplayTmp += restProps.props.searchCategory;

      if (searchDisplayTmp != '' && restProps.props.searchKeyword != '') {
        searchDisplayTmp += ' • ';
      }

      searchDisplayTmp += restProps.props.searchKeyword;

      setSearchDisplayState(searchDisplayTmp);
    }
  }

  function pressSearch() {
    if (restProps.props.sourceUI && restProps.props.sourceUI != null) {
      if (restProps.props.sourceUI == 'Home') {
        props.pressSearch();
      } else {
        if (searchDisplayState == '') {
          window.location = '/?search';
        } else {
          window.location =
            '/?search&product=' +
            searchProductState +
            '&category=' +
            searchCategoryState +
            '&categoryID=' +
            searchCategoryIDState +
            '&keyword=' +
            searchKeywordState;
        }
      }
    }
  }

  useEffect(() => {
    if (sorraUser) {
      const { phone_verified, post_signup_questionnaire_filled } = sorraUser;

      if (!phone_verified) {
        alert('請完成註冊程序。');
        window.location = '/phone';
        return;
      }

      if (phone_verified && !post_signup_questionnaire_filled) {
        alert('請完成註冊程序。');
        window.location = '/question1';
      }
    }
  }, [sorraUser]);

  return (
    <>
      <div className="fixed left-0 top-0 z-[60] w-full">
        <div
          className={classnames(
            'relative inset-x-0 top-0 flex h-[45px] items-center justify-center bg-sorra-blue py-3',
            {
              hidden: notifications.length === 0,
            }
          )}
        >
          {notifications.map((notification, index) => {
            return (
              <div
                key={notification.id}
                className={classnames(
                  'absolute inset-0 flex items-center justify-center text-sm text-white transition-opacity duration-300 ease-in-out',
                  {
                    'pointer-events-none opacity-0':
                      index !== currentNotificationIndex,
                    'pointer-events-auto opacity-100':
                      index === currentNotificationIndex,
                  }
                )}
              >
                <a className="truncate" href={notification.url} target="_blank">
                  {notification.text}
                </a>
              </div>
            );
          })}
        </div>

        <nav
          className={'menubar-normal relative w-full justify-center border-b'}
          style={{
            backgroundColor: 'rgba(255, 255, 255, ' + backgroundOpacity + ')',
            borderBottomColor: 'rgba(209, 219, 239, ' + backgroundOpacity + ')',
          }}
        >
          <MaxWidthContainer className="mx-auto hidden h-full flex-wrap items-center justify-between p-4 lg:flex">
            <div className="flex h-full items-center">
              <a href="/">
                <img
                  className="menubar-normal-logo"
                  alt="sorra"
                  src="/images/sorra.png"
                />
              </a>

              <div
                className={
                  'menubar-normal-search-frame relative' +
                  (backgroundOpacity < 1.0 ? ' transpirant' : '')
                }
                onClick={() => {
                  pressSearch();
                }}
              >
                <div className="menubar-normal-search-icon pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <img src="/svgs/search.svg"></img>
                </div>
                <input
                  type="text"
                  className="bg-gray-0 menubar-normal-search block w-full rounded-lg border border-gray-300 p-2.5 pl-10 text-sm text-sorra-gray-900 focus:border-blue-500 focus:ring-blue-500"
                  defaultValue={searchDisplayState}
                  placeholder="搜尋美妝產品・話題"
                />
              </div>
            </div>

            <div className="flex h-full items-center">
              <div className="relative flex items-center gap-x-12">
                <Link href="/ranking">美妝排行</Link>
                <Link href="/rewards">兌換禮物</Link>
                <Link href="/campaign">活動</Link>
                <Link href="/rewards?tab=code">優惠碼</Link>
                {!sorraUser && <Link href="/membership">會員制度</Link>}

                {sorraUser ? (
                  <div className="flex gap-x-2">
                    <button
                      ref={refs.setReference}
                      className="flex h-[41px] items-center justify-center rounded-[100px] border-[1px] border-[#E7E7E7] bg-[#FFFFFF] px-[8px] py-[4px] text-white"
                      onClick={() => {
                        setShowProfileMenu(!showProfileMenu);
                        // signOut();
                        // console.log('gggggg');
                        // window.location = '/profile';
                      }}
                    >
                      <div className="mr-[8px] flex items-center justify-center rounded-[12px] bg-[#FCE1E5]">
                        <img
                          src="/svgs/sorra-logo-small.svg"
                          className="p-[6px]"
                        />
                      </div>
                      <p className="text-sm text-black">
                        ${sorraUser.score || 0}
                      </p>
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-x-4">
                    <button
                      className="menubar-normal-search-button flex h-[41px] items-center justify-center rounded-lg bg-sorra-pink px-5 text-white"
                      onClick={() => {
                        // console.log({ pathname, query, asPath })
                        MixpanelConfig.track('signinup_from_nav');
                        redirectToLogin(asPath);
                      }}
                    >
                      <img src="/svgs/user-w.svg"></img>
                      <p className="text-sm text-white">會員登陸</p>
                    </button>
                  </div>
                )}
                {showProfileMenu && (
                  <div
                    ref={refs.setFloating}
                    style={{
                      ...floatingStyles,
                      boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.10)',
                    }}
                    className="flex min-w-[234px] flex-col gap-y-8 rounded-lg bg-white px-[30px] py-[22px]"
                  >
                    <Link
                      className="text-left"
                      href={`/p/${sorraUser.referral_code}`}
                      onClick={() => {
                        setShowProfileMenu(false);
                      }}
                    >
                      我的美妝檔案
                    </Link>
                    <Link
                      className="text-left"
                      href="/profile"
                      onClick={() => {
                        setShowProfileMenu(false);
                      }}
                    >
                      個人中心
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </MaxWidthContainer>

          <MaxWidthContainer
            className={
              'mx-auto flex h-full flex-wrap items-center justify-between p-4 lg:hidden' +
              (restProps.props.sourceUI == 'Search' ? ' hidden' : '')
            }
          >
            <a href="/">
              <div className="flex h-full items-center">
                <img
                  className="menubar-normal-logo"
                  alt="sorra"
                  src="/images/sorra.png"
                />
              </div>
            </a>

            <div className="flex h-full items-center">
              <div className="relative flex items-center gap-x-6">
                <button
                  onClick={() => {
                    pressSearch();
                  }}
                >
                  <img src="/svgs/search.svg"></img>
                </button>
                <div className="flex gap-x-4">
                  {!sorraUser ? (
                    <button
                      className="menubar-normal-search-button flex h-[41px] items-center justify-center rounded-lg bg-sorra-pink px-3 text-white"
                      onClick={() => {
                        redirectToLogin(asPath);
                      }}
                    >
                      <img src="/svgs/user-w.svg"></img>
                      <p className="text-sm text-white">會員登陸</p>
                    </button>
                  ) : (
                    <button
                      className="flex h-[41px] items-center justify-center rounded-[100px] border-[1px] border-[#E7E7E7] bg-[#FFFFFF] px-[8px] py-[4px] text-white"
                      onClick={
                        /*firSignout*/ () => {
                          window.location = '/profile';
                        }
                      }
                    >
                      <div className="mr-[8px] flex items-center justify-center rounded-[12px] bg-[#FCE1E5]">
                        <img
                          src="/svgs/sorra-logo-small.svg"
                          className="p-[6px]"
                        />
                      </div>
                      <p className="text-sm text-black">
                        ${sorraUser.score || 0}
                      </p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </MaxWidthContainer>

          <MaxWidthContainer
            className={
              'mx-auto flex h-full flex-nowrap items-center justify-between p-4 lg:hidden' +
              (restProps.props.sourceUI == 'Search' ? '' : ' hidden')
            }
          >
            <a href="/">
              <div className="flex h-full items-center pr-[16px]">
                <img
                  src="/svgs/chevron-left.svg"
                  className="h-[24px] w-[24px]"
                />
              </div>
            </a>

            <div
              className={'relative grow'}
              onClick={() => {
                pressSearch();
              }}
            >
              <div className="menubar-normal-search-icon pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <img src="/svgs/search.svg"></img>
              </div>
              <input
                type="text"
                id=""
                className="bg-gray-0 block w-full rounded-lg border border-gray-300 p-2.5 pl-14 text-sm text-sorra-gray-900 focus:border-blue-500 focus:ring-blue-500"
                defaultValue={searchDisplayState}
                placeholder="搜尋美妝產品・話題"
              />
            </div>
          </MaxWidthContainer>
        </nav>
      </div>
    </>
  );
};

export default Menubar;
